<template>
  <div class="partners page">
    <div class="section section__header">
      <div class="section__background section_anim" v-rellax="{speed: -2 }">
        <img
            v-if="background"
            :src="require(`@/assets/img/backgrounds/${background.file}`)"
            alt="img"
        />
      </div>

      <div class="section__content uk-container uk-container-large">
        <div class="section__navigation section__navigation_light">
          <p><span><a href="/">Главная</a> |</span> Партнерам</p>
        </div>

        <div class="section__widgets">
          <div class="section_sm">
            <div class="section__title">
              <span class="section__title_block">Японский автомобильный парфюм <span class="uppercase">{{ appName }}</span> эксклюзивно в Юником</span>
              <div class="links">
                <a target="_blank" :href="appLinkPartner" class="button__link pulse">
                  №1 по отзывам <br>
                  потребителя
                </a>
                <a target="_blank" href="/doc/Certificate2025.pdf" class="button__link button__link_light pulse">
                  Сертификат <br>
                  эксклюзивности
                </a>
              </div>
            </div>
            <div class="section__bottom">
              <div class="section__more"> На территории Российской Федерации эксклюзивным дистрибьютором японской компании  <span class="uppercase">{{ appName }}</span> является компания «Юником».</div>
              <company-info v-if="isDevice !== 'desktop'" />

            </div>
          </div>

          <company-info v-if="isDevice === 'desktop'" />
        </div>

        <div
            v-if="background.title"
            class="section__place"
            :class="{ section__place_dark: background.color }"
        >
          <span>{{background.title}}</span>
        </div>
      </div>

      <div class="background-blue-transparent"></div>
    </div>

    <div class="section section__main">
      <div class="uk-container uk-container-large">
        <div class="section__container">

          <div class="partners__body">
            <h2 class="partners__title">Преимущества<br>партнерам</h2>
            <div class="partners__block">
              <div class="partners-info">
                <p class="partners-info__title">Персональный<br>менеджер</p>
                <p class="partners-info__block">Наш
                  специалист ответит на все вопросы, приедет в ваш магазин и подскажет,
                  какой товар будет пользоваться спросом именно у вашего покупателя.
                </p>
              </div>
              <div class="partners-info">
                <p class="partners-info__title">Маркетинговая<br>поддержка</p>
                <p class="partners-info__block">Предоставляем
                  промо-материалы разного формата,
                  которые помогут лучшерассказать о новом товаре покупателям.
                </p>
              </div>
              <div class="partners-info">
                <p class="partners-info__title">Подробное<br>обучение</p>
                <p class="partners-info__block">Наш
                  региональный представитель поделится свежими
                  знаниями о продукции брендов и лично покажет, как пользоваться новинками.
                </p>
              </div>
            </div>
          </div>

          <div class="block block_right">
            <div class="block_sm-partners">
              <div class="register__title">Зарегистрируйтесь на сайте компании, чтобы узнать цены</div>
              <div class="register__button">
                <a href="https://uniqom.ru/register" target="_blank">Зарегистрироваться</a>
              </div>
            </div>
            <div class="block_bg block__image">
              <img
                  src="@/assets/img/banner_partner.png"
                  alt="banner"
              />
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="section section__main">
      <div class="section__liner">

        <div class="partners__line">
          <div class="uk-container uk-container-large">
            <div class="partners__container">
              <div class="line__title">
                <p>Скидка до 20% на первые 2 месяца.</p>
                <p>Чтобы сохранить скидку, необходимо выйти на закуп в 40 000 руб</p>
              </div>
              <div class="line__button">
                <a href="https://uniqom.ru/register" target="_blank">
                  WELCOME-ПЕРИОД
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="section section__main">
      <div class="uk-container uk-container-large">
        <div class="section__liner">

          <div class="partners__body">
            <h2 class="partners__title">Удобная доставка</h2>
            <div class="partners__block">
              <div class="partners-info">
                <p class="partners-info__title">Экспресс</p>
                <p class="partners-info__block">Оформите заказ утром и товар будет у вас уже вечером.
                  Бесплатная экспресс-доставка осуществляется дважды в день.
                </p>
              </div>
              <div class="partners-info">
                <p class="partners-info__title">Курьер</p>
                <p class="partners-info__block">Оформите заказ и нужные автозапчасти будут у вас уже через 1,5 часа.
                  Заказ от 2500 доставляем бесплатно.
                </p>
              </div>
              <div class="partners-info">
                <p class="partners-info__title">Транспортная компания</p>
                <p class="partners-info__block">Живете в отдаленном регионе? Доставим товар в ваш город транспортной компанией.
                  Доставка до ТК - бесплатно.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <PartnerMap
        :isDevice="isDevice"
        :class="{'desktop-map' : isDevice === 'desktop' }"
        @feedback="$emit('feedback', { enabled: true })"
    />
  </div>
</template>

<script>
import BackgroundsJson from '@/assets/data/backgrounds.json'
import PartnerMap from '../components/PartnerMap'
import CompanyInfo from '../components/CompanyInfo'

export default {
  components: {
    PartnerMap,
    CompanyInfo
  },
  props: {
    isDevice: null,
  },
  computed: {
    background() {
      const backgrounds = BackgroundsJson.filter(item => {
        return item.route === this.$route.path
      })
      if (backgrounds) {
        let b = backgrounds[Math.floor(Math.random() * backgrounds.length)]
        this.$emit('updateHeaderTheme', {theme: b.header})
        return b
      }
      return null
    },
    appName() {
      return process.env.VUE_APP_NAME
    },
    appLinkPartner() {
      return process.env.VUE_APP_LINK_PARTNER
    }
  },
  metaInfo: {
    title: 'Партнерам',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>